import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/father-and-daughter-brushing-teeth-in-bathroom.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'
import BtnOutlined from 'components/button/outlined-button'
import TelLinkSimple from 'components/tel-link-simple'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const PreventativeDentistryPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const service_preventative_1 = data.service_preventative_1?.childImageSharp?.fluid
  const service_preventative_2 = data.service_preventative_2?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Preventative Dentistry - The Dentists At Gateway Crossing</title>
      <meta name="description" content="We offer many types of preventative dentistry services to help you keep that smile looking bright and healthy. Browse the page below to learn more. It's easy" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Preventative Dentistry - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="We offer many types of preventative dentistry services to help you keep that smile looking bright and healthy. Browse the page below to learn more. It's easy" />
      <meta property="og:url" content="https://thedentistsatgc.com/services/preventative-dentistry/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1333" />
      <meta property="og:image:height" content="2000" />
      <meta property="og:image:alt" content="father-and-daughter-brushing-teeth-in-bathroom" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Preventative Dentistry - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="We offer many types of preventative dentistry services to help you keep that smile looking bright and healthy. Browse the page below to learn more. It's easy" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
                PREVENTIVE DENTAL SERVICES MCCORDSVILLE
              </p>
              <h1 className="text-light">
                Preventive Dentistry
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer many types of preventative dentistry services to help you keep that smile looking bright and healthy. Browse the page below to learn more. It’s easy to schedule your appointment with us.</p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#pd-section-3"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-12">
              <p className ="section-1-text">PREVENTIVE DENTAL CARE</p>
              <h2 className ="section-2-header">What Are The Benefits Of Preventive Dentistry?</h2>
              <p>Your smile tells others a lot about you. It can communicate health, help you make friends, and make you appear more trustworthy. Preventing tooth decay and oral disease is essential to maintaining a life long healthy smile. We understand how important it is for you to keep your teeth and gums healthy and that is why we off several preventive services and products.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Better Pay & Benefits</h3>
                <p>Studies have shown that straight teeth and beautiful smiles can impact things like getting a promotion or securing a new job.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Great First Impressions</h3>
                <p>People usually remember a great smile long after they meet for the first time. If your teeth keep you from smiling, you may come across as rude or unfriendly.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Boosts Your Self Esteem</h3>
                <p>Feeling confident can change every interaction in your life. Imagine being able to to talk, laugh, smile, and let people see your teeth with out feeling embarrassed.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Improves Overall Dental Health</h3>
                <p>Once you invest the time and energy into a great smile you're more likely to take make sure it stays that way. Brushing, flossing, whitening, suddenly become more important.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Smile More & Live Longer</h3>
                <p>Researchers have found that you can live a longer healthier life just by smiling more often. When your smile is beautiful, you're going to show it off.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">High Trust & Productivity</h3>
                <p>Smiling increases our perception of being trustworthy. At the same time it elevates our mood and energy levels. When you're happy, you're productive!</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-6">
              <Img fluid={service_preventative_1} alt="Father and daughter brushing their teeth" />
            </div>
            <div className="col-lg-6">
              <Img fluid={service_preventative_2} alt="Woman holding a dental floss" />
            </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="The Dentists at Gateway Crossing"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                  Love Your Dentists!
                </p>
                <h3 className="text-light">
                  Don't take another trip to the dentist that you don't enjoy!
                </h3>
                <p className="hero-text text-light">
                  Your experience is our number one concern. It’s why so many people come to us for dental care. Call TODAY!
                </p>
                <BtnOutlined url="tel:3176439434" class="btn-rounded" label="(317) 643-9434" />
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">PREVENTIVE DENTAL DETAILS</p>
              <h2 className ="section-3-header">What is Preventive Dentistry ?</h2>
              <p>Preventive dentistry is the first line of defense against tooth decay and gum disease. Much of this can be done at home by brushing and flossing. However, there are routine dental procedures that are necessary to keep your smile looking great. We know how important it is for you to keep your teeth happy and healthy. That is why we offer a full line of preventive dentistry services. Dr. Vogt is here to help educate you on how to keep your smile looking great while providing the best experience possible. </p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Is Preventive Dentistry Right For You?</h3>
                <p>Call us biased, but we think it’s right for everyone! If staying healthy and keeping your teeth for the rest of your life sounds like something you would like to do then you should pay close attention to the preventive measures required to maintain a healthy mouth. You should be brushing and flossing regularly at home but you also need to come in for the occasional appointment.</p>
                <p>You use your teeth every day when eating, talking, and smiling. Over time, if you don’t take care of your teeth and gums they will start to deteriorate. Cavities and gum disease can be prevented or at least caught early enough to prevent having to take more extreme measures like root canals, extractions, or surgery. </p>
                <p>Often times when someone neglects coming to the dentist they think they’re saving money. In reality, those people end up spending a lot more on advanced procedures. If they had invested the time and energy to see the dentist regularly, they wouldn’t have had to deal with painful tooth decay, bad breath, or gum disease.</p>
                <p>If you’ve been thinking about skipping the regular checkup and procedures, don’t! There is no better plan for proper oral health than preventive dentistry.</p>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Our Preventive Dentistry Services List</h3>
                <p>Our preventive dentistry service incorporates check-ups and teeth cleaning, oral cancer cleaning, athletic mouth guards and dental sealants.</p>
                <h4 className ="section-3-header">Click the "+" Below To Expand Any Service</h4>
                <div className="accordion">
                  <details open>
                    <summary aria-describedby="Check-ups and Teeth Cleaning:">Check-ups and Teeth Cleaning:</summary>
                    <div className="accordion-content">
                      <p>Regular check ups and cleanings are important. You should try not skip these as they allow you to keep your dental costs down by avoiding more expensive procedures down the road.</p>
                    </div>
                  </details>
                  <details>
                    <summary aria-describedby="Oral Cancer Screening:">Oral Cancer Screening:</summary>
                    <div className="accordion-content">
                      <p>We’ve also made oral cancer screenings an integral part of every check-up. We use tested technology to reveal possibly cancerous and precancerous cells within the mouth.</p>
                    </div>
                  </details>
                  <details>
                    <summary aria-describedby="Dental Sealants:">Dental Sealants:</summary>
                    <div className="accordion-content">
                      <p>Additionally, we can also use dental sealants to prevent your teeth from decay and keep the deep grooves on your molars and premolars completely clean.</p>
                    </div>
                  </details>
                  <details>
                    <summary aria-describedby="Athletic Mouthguards:">Athletic Mouthguards:</summary>
                    <div className="accordion-content">
                      <p>Athletic adults and children need to protect their teeth during sports. Our custom-made athletic mouth guards will help to protect teeth, lips, and tongue from traumas that might occur on the field. Our athletic mouth guards are a perfect way to protect your smile while you enjoy sports activities for years to come.</p>
                    </div>
                  </details>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Preventive Dental Procedures Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <TelLinkSimple />.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default PreventativeDentistryPage

export const query = graphql`
  query PreventativeDentistryPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_preventative_1: file(name: { eq: "preventive_1" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_preventative_2: file(name: { eq: "preventive_2" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
