import React, { Component } from 'react'
import { Link } from 'gatsby'
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";

interface Props {
  path: string
  label: string
  primary: boolean
}

class TelLink extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    typeof window !== "undefined" && window.gtag("conversion", "click", { send_to: ["AW-708502388/dOiKCOrXoqsBEPTG69EC"]});
    gtag_report_conversion('tel:3176439434');
    console.log("gtag_report_conversion");
  }    

  render() {
      return (
        <a onClick={this.handleClick} href="tel:3176439434">317-643-9434</a>
      )
  }
}

export default TelLink;